@import url("./assets/fonts/stylesheet.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*auto select bacground fix*/
input:-internal-autofill-selected {
  background: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Rich Text Editor */
.Gr-richTextEditor .ql-toolbar.ql-snow {
  @apply rounded-t-[20px];
}
.Gr-richTextEditor .ql-container.ql-snow {
  @apply rounded-b-[20px];
}

/* Website Scrollbar*/
/* Website Scroll Style */
::-webkit-scrollbar {
  @apply h-[5px] w-[10px];
}

::-webkit-scrollbar-track {
  border-radius: 100vw;
  margin-block: 1px;
  margin-inline: 10px;
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300;
  border-radius: 100vw;
}

.rugbyplaceholder input::placeholder,
.rugbyplaceholder {
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif !important ;
}

input {
  font-size: 14px !important;
}

.iframemaincss iframe {
  width: 200px;
  height: auto;
}

.iframemaincss iframe .ytp-large-play-button {
  background: none;
  width: 20px;
}

/**
 * ----------------------------------------
 * animation slide-tr
 * ----------------------------------------
 */
.slide-tr {
  -webkit-animation: slide-tr 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-tr 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(100px);
    transform: translateY(-100px) translateX(100px);
  }
}
@keyframes slide-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(100px);
    transform: translateY(-100px) translateX(100px);
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000; /* Adjust the z-index value as needed */
}

.MuiSelect-select .notranslate::after {
  opacity: .4 !important;
}

.post-image-cls {
  width: 100%;
  height: 320px;
  /* object-fit: fill; */
  position: relative;
  border-radius: 10px;
}

.errorMessageCss{
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-bottom: 0;
}

.compeleteProfileForm .MuiInputBase-root, .compeleteProfileForm .MuiAutocomplete-root .MuiInputBase-input {
  padding: 0px 10px 0 0px!important;
  font-family: Inter var, sans-serif;
}
.compeleteProfileForm .MuiInputBase-root textarea, .compeleteProfileForm textarea  {
  padding: 10px!important;
  font-size: 14px!important;
}
.compeleteProfileForm .MuiInputBase-root .MuiSelect-select {
  padding: 0px 0px 0px 10px!important;
}
.compeleteProfileForm .MuiInputBase-root .MuiSvgIcon-root {
  margin-right: 0px;
} 
.compeleteProfileForm .MuiInputBase-input, .compeleteProfileForm .MuiAutocomplete-root .MuiInputBase-root {
  padding: 0px 10px!important;
}
.compeleteProfileForm .MuiInputBase-input::placeholder, .compeleteProfileForm textarea::placeholder {
  font-family: Inter var, sans-serif;
  font-weight: 400;
}
.compeleteProfileForm label {
  font-size: 14px!important;
  margin-bottom: 4px;
}
.filterScroller::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.filterScroller::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.filterScroller::-webkit-scrollbar-thumb {
  background: #c7c7c7; 
}

/* Handle on hover */
.filterScroller::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.filterScroller .MuiListItemIcon-root {
  min-width: auto;
}
.qualification-uploaded-img svg {
 width: 20px;
 height: 20px;
}

/* --------Chat Css --------- */
.chatCol::after {
  content: '';
  background-color: #EDEDED;
  height: 1px;
  width: calc(100% - 74px);
  bottom: 0;
  right: 0;
  position: absolute;
}
.chat-row:last-child .chatCol::after {
  display: none
}

.search-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}

.MuiFormHelperText-filled, .MuiFormHelperText-contained {
  margin: 0px!important;
  font-size: 13px!important; 
  color: #ef4444!important; 
  text-overflow: unset!important; 
  font-family: Inter var, sans-serif!important;
}

/* -----------------Club Mob Header menu Acc ------------*/
.club-menu-accordian::before {
  display: none;
}
.club-menu-accordian .MuiButtonBase-root {
  min-height: auto;
  padding: 8px 16px;
}
.club-menu-accordian .MuiButtonBase-root.Mui-expanded {
  min-height: auto;
}
.club-menu-accordian .MuiAccordionSummary-content {
  margin: 0px!important;
}
.club-menu-accordian .MuiAccordionDetails-root {
  padding: 8px 16px;
}

@media (max-width: 824px) {
  .post-image-cls {
    height: 300px;
  }
}
@media (max-width: 650px) {
  .post-image-cls {
    height: auto;
    min-height: 150px;
    object-fit: cover;
  }
}
.lpauh{
  color: #193766;
}
.m-b-h{
  color: #292D32;
}
.bg-brown{
  background-color: #a52a2a;
}
.placeholder-text-color {
  opacity: .75;
}
.bg-light-red{
  background-color: #eccccc70;
}

.partner{
    /* width: 30rem; */
    height: 15rem;
    margin: 1rem;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
}
.align-v-center{
  transform: translateY(20%);
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(0.5% + 1px) !important;
}
.react-multiple-carousel__arrow{
  background: rgb(0 0 0 / 16%) !important;
}